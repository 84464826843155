<template>
  <svg class="svg-icon" aria-hidden="true" :style="styleSheet">
    <use :xlink:href="iconName" />
  </svg>
</template>
 
<script>
export default {
  name: "SvgIcon",
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    size: {
      type: Number | String,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`;
    },
    styleSheet() {
      let styleStr = "";
      if (this.size) {
        styleStr += "font-size: " + this.size + "px;";
      }
      if (this.color) {
        styleStr += "color: " + this.color + ";";
      }
      return styleStr;
    },
  },
};
</script>
 
<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>