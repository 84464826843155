import request from '@/utils/request'


export function loginApp(params) {
  return request({
    url: 'apkApi/login',
    method: 'post',
    params: params
  })
}

export function logout() {
  return request({
    url: 'auth/logout',
    method: 'delete'
  })
}

export function changeLogin(id) {
  return request({
    url: 'auth/changeLogin/' + id,
    method: 'post',
  })
}

