/**
 * @description 路由守卫
 */
import router from '@/router'
import store from '@/store'
import { permissionAuth } from "@/utils/index"
const defaultSettings = require('../config/settings.js')

router.beforeEach(async (to, from, next) => {
  if (to.path === "/index" || to.path === "/401" || to.path === "/403") {
    next();
  } else if (store.getters.token) {
    next();
  } else if (to.query.st) {
    let service = defaultSettings.service + to.path;
    // 有st授权
    let data = await store.dispatch("user/login", {
      st: to.query.st,
      service: service,
    });
    if (permissionAuth(to, data.user)) {
      next();
    } else {
      router.push({
        path: "/402",
      });
    }
  } else if (to.path.endsWith("form") || to.path.endsWith("edit") || to.path.endsWith("add") || to.path.endsWith("detail")) {
    if (store.getters.token) {
      next();
    }
  } else {
    // 未授权
  }
})


router.afterEach((to) => {
  if (to.query && to.query.title) {
    document.title = to.query.title
  } else {
    document.title = to.meta.title
  }
})
