
const URL =
  process.env.NODE_ENV === 'development'
    ? 'http://192.168.2.34:4056'
    : 'http://xg.gscat.edu.cn:2007/rootApi'


const WebURL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://xg.gscat.edu.cn'


module.exports = {
  /**
  * @description 端口
  */
  port: 2007,
  /**
   * @description 网站标题
   */
  title: '农职学工',
  /**
   * @description 记住密码状态下的token在Cookie中存储的天数，默认1天
   */
  tokenCookieExpires: 1,
  /**
   * @description 记住密码状态下的密码在Cookie中存储的天数，默认1天s
   */
  passCookieExpires: 1,
  /**
   * @description token key
   */
  TokenKey: 'token',
  /**
   * @description 请求超时时间，毫秒（默认2分钟）
   */
  timeout: 12000,
  /**
  * @description 后台请求地址
  */
  BaseURL: URL + '',
  /**
   * @description token在localStorage、sessionStorage、cookie存储的key的名称
   */
  tokenTableName: 'sso-token',
  /**
  * @description token存储位置localStorage sessionStorage cookie
  */
  storage: 'localStorage',
  /**
   * @description apk应用地址（st解码使用）
   */
  service: 'http://xg.gscat.edu.cn:2007',
  /**
 * @description 人脸识别接口地址
 */
  FaceService: 'https://xg.gscat.edu.cn/micp/openapi',
  /**
   * @description web地址（自定义奖助调用web模块）
   */
  webService: WebURL + '',//
  /**
  * @description 文件上传
  */
  fileUploadApi: URL + '/api/storage',
  /**
   * @description 图片预览
   */
  imageViewApi: URL + '/api/storage/viewImg/',
  /**
  * @description 学生头像预览
  */
  stuViewApi: URL + '/api/xj/viewImgApk/',
  /**
  * @description 文件下载
  */
  fileDownloadApi: URL + '/api/storage/download/',

}
