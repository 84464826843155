import { TokenKey } from '@/config/settings'
import { loginApp, logout } from '@/api/authorization'

const user = {
  state: {
    token: '',
    user: {},
    roles: []
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    }
  },
  actions: {
    // 登录
    async login({ commit }, stToken) {
      const { token, user } = await loginApp(stToken)
      if (token) {
        await commit('SET_TOKEN', token)
      }
      if (user) {
        await commit('SET_USER', user.user)
        await commit('SET_ROLES', user.user.roles)
      }
      return user
    },
    // 手动设置登录
    CustomLogin({ commit }, data) {
    },
    // 获取用户信息
    GetInfo({ commit }) {
    },
    /**
     * @description 退出登录
     * @param {*} { dispatch }
     */
    async logout({ dispatch }) {
      await logout()
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_USER', {})
    },
    changeUser({ commit }, data) {
      const { token, user, roles } = { ...data }
      commit('SET_TOKEN', token)
      commit('SET_ROLES', roles)
      commit('SET_USER', user)
    },
  }
}

export default user
