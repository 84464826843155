import axios from 'axios'
import cache from "@/utils/cache";
import Config from '@/config/settings'
import store from '../store'
import { Notify } from "vant";
import router from '@/router'

// 创建axios实例
const service = axios.create({
  baseURL: Config.BaseURL, // api 的 base_url
  timeout: Config.timeout // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {

  const token = store.getters['token']
  // 规范写法 不可随意自定义
  if (token) config.headers['Authorization'] = `Bearer ${token}`

  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false

  config.headers['Content-Type'] = 'application/json'

  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url;                  // 请求地址
      const s_data = sessionObj.data;                // 请求数据
      const s_time = sessionObj.time;                // 请求时间
      const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = '数据正在处理，请勿重复提交';
        console.warn(`[${s_url}]: ` + message)
        return Promise.reject(new Error(message))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  return config
},
  error => {
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    // 兼容blob下载出错json提示
    if (error.response.data instanceof Blob && error.response.data.type.toLowerCase().indexOf('json') !== -1) {
      const reader = new FileReader()
      reader.readAsText(error.response.data, 'utf-8')
      reader.onload = function (e) {
        const errorMsg = JSON.parse(reader.result).message
        Notify({
          type: 'danger',
          message: errorMsg,
          duration: 5000,
        });

      }
    } else {
      let code = 0
      try {
        code = error.response.status
      } catch (e) {
        if (error.toString().indexOf('Error: timeout') !== -1) {
          Notify({
            type: 'danger',
            message: '网络请求超时',
            duration: 5000,
          });
          return Promise.reject(error)
        }
      }
      if (code) {
        if (code === 401) {
          // 统一认证失败
          router.push({ path: "/401" });
          if (error.response.data !== undefined) {
            Notify({
              type: 'warning',
              message: "认证失败",
              duration: 3000,
            });
          }
        } else if (code === 403) {
          // 无账号处理
          router.push({ path: "/403" });
          if (error.response.data !== undefined) {
            Notify({
              type: 'warning',
              message: "系统未检到账号",
              duration: 3000,
            });
          }
        } else {
          const errorMsg = error.response.data.message
          if (errorMsg !== undefined) {
            Notify({
              type: 'danger',
              message: errorMsg,
              duration: 5000,
            });
          }
        }
      } else {
        Notify({
          type: 'danger',
          message: '接口请求失败',
          duration: 5000,
        });
      }
    }
    return Promise.reject(error)
  }
)
export default service
