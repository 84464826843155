import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = {}

modulesFiles.keys().forEach((key) => {
  modules[key.replace(/(modules|\/|\.|js)/g, '')] = {
    ...modulesFiles(key).default,
    namespaced: true,
  }
})
const store = new Vuex.Store({
  modules,
  getters
})

export default store
