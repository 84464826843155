<template>
  <div class="border-line">
    <van-field :required="required" readonly clickable :disabled="disabled" name="picker" :value="value" :right-icon="showPicker?'arrow-up':'arrow-down'" :label="label" @click="clickShowPicker">
      <template #input>
        <span v-if="value && showLabel" :style="disabled?'color:#c8c9cc;font-size: 14px;':''">{{showLabel}}</span>
        <span v-else-if="!vaildTitle" style="color:#C0C4CC;font-size: 14px;">{{placeholder}}</span>
        <span v-else-if="vaildTitle" style="color:#FF7577;font-size: 14px;">{{vaildTitle}}</span>
      </template>
    </van-field>
    <van-popup v-model="showPicker" :lazy-render='false' round position="bottom" :style="{ height: '40%' }">
      <van-picker show-toolbar ref="picker" :columns="parseInt(changeLevel) === 3 ? provinceCityAreaColumns : provinceCityColumns" value-key="label" @confirm="onConfirm" @change="onChange" @cancel="closePicker" />
    </van-popup>
  </div>
</template>

<script>
import { getProvinceTree, getProvinceAndCityTree } from "@/api/common/common";

export default {
  name: "regionalCascader",
  props: {
    // 选择器等级 2 省-市 3 省-市-县
    changeLevel: {
      type: Number,
      default: 3,
    },
    placeholder: {
      // 文本提示
      type: String,
      default: "请选择",
    },
    value: {
      // v-model
      type: Array | Number | String,
      default: [],
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPicker: false,
      provinceCityAreaColumns: [],
      provinceCityColumns: [],
      showLabel: null,
      vaildTitle: "",
    };
  },
  // 监听回显
  watch: {
    value(newval) {
      if (!(newval instanceof Array)) {
        this.revolveShow(JSON.parse(newval));
      }
    },
  },
  created() {
    if (parseInt(this.changeLevel) === 3) {
      getProvinceTree().then((citys) => {
        this.provinceCityAreaColumns = [
          {
            values: citys, // 设置的页面初始值
            className: "column1",
          },
          {
            values: citys[0].children,
            className: "column2",
          },
          {
            values: citys[0].children[0].children,
            className: "column3",
          },
        ];
        if (!(this.value instanceof Array)) {
          this.revolveShow(JSON.parse(this.value));
        }
      });
    } else {
      getProvinceAndCityTree().then((citys) => {
        this.provinceCityColumns = [
          {
            values: citys,
            className: "column1",
          },
          {
            values: citys[0].children,
            className: "column2",
          },
        ];
        if (!(this.value instanceof Array)) {
          this.revolveShow(JSON.parse(this.value));
        }
      });
    }
  },
  methods: {
    // 完成选择回调
    onConfirm(value, index) {
      let codeArr = new Array();
      let province = value[0];
      codeArr[0] = province.value;
      // 三级联动
      if (this.changeLevel === 3) {
        let city = value[1];
        let area = value[2];
        this.showLabel =
          province.label + " / " + city.label + " / " + area.label;
        codeArr[1] = city.value;
        codeArr[2] = area.value;
        // 两级联动
      } else {
        let city = value[1];
        this.showLabel = province.label + " / " + city.label;
        codeArr[1] = city.value;
      }
      this.vaildTitle = "";
      //van-field 组件 只接受 Number和String的值
      this.$emit("input", JSON.stringify(codeArr));
      this.showPicker = false;
    },
    // 选中后处理下一级数据
    onChange(picker, value, index) {
      if (this.changeLevel === 3) {
        if (index === 0) {
          picker.setColumnValues(1, value[0].children);
          picker.setColumnValues(2, value[0].children[0].children);
          picker.setColumnIndex(1, 0);
          picker.setColumnIndex(2, 0);
        } else if (index === 1) {
          picker.setColumnValues(2, value[1].children);
          picker.setColumnIndex(2, 0);
        }
      } else {
        if (index === 0) {
          picker.setColumnValues(1, value[0].children);
          picker.setColumnIndex(1, 0);
        }
      }
    },
    // 弹出层
    clickShowPicker() {
      if (!this.disabled) {
        this.showPicker = true;
      }
    },
    // 取消选择
    closePicker(value, index) {
      this.showPicker = false;
    },
    // 编辑数据回显
    revolveShow(val) {
      if (val && val.length < 1) return;
      let name = "";
      if (parseInt(this.changeLevel) === 3) {
        if (this.provinceCityAreaColumns.length !== 3) return;
        if (!this.provinceCityAreaColumns[0]) return;
        this.provinceCityAreaColumns[0].values.forEach((item, index) => {
          // 省
          if (val[0] === item.value) {
            name += item.label;
            this.provinceCityAreaColumns[0]["defaultIndex"] = index;

            // 市
            this.provinceCityAreaColumns[1]["values"] = item.children;
            if (item.children.length > 0) {
              item.children.forEach((level2, index2) => {
                if (val[1] === level2.value) {
                  name += " / " + level2.label;
                  this.provinceCityAreaColumns[1]["defaultIndex"] = index2;

                  // 县
                  this.provinceCityAreaColumns[2]["values"] = level2.children;
                  if (level2.children.length > 0) {
                    level2.children.forEach((level3, index3) => {
                      if (val[2] === level3.value) {
                        name += " / " + level3.label;
                        this.provinceCityAreaColumns[2]["defaultIndex"] =
                          index3;
                      }
                    });
                  }
                }
              });
            }
          }
        });
      } else {
        if (this.provinceCityColumns.length !== 2) return;
        if (!this.provinceCityColumns[0]) return;
        this.provinceCityColumns[0].values.forEach((item, index) => {
          // 省
          if (val[0] === item.value) {
            name += item.label;
            this.provinceCityColumns[0]["defaultIndex"] = index;

            // 市
            this.provinceCityColumns[1]["values"] = item.children;
            if (item.children.length > 0) {
              item.children.forEach((level2, index2) => {
                if (val[1] === level2.value) {
                  name += " / " + level2.label;
                  this.provinceCityColumns[1]["defaultIndex"] = index2;
                }
              });
            }
          }
        });
      }
      this.showLabel = name;
    },
    validate() {
      if (this.value && this.showLabel) {
        return true;
      } else {
        this.vaildTitle = "请选择" + this.label;
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.van-cell {
  font-size: 15px;
}
</style>

