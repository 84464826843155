<template>
  <div class="border-line">
    <van-field :required="required" name="radio" :label="label" :disabled="disabled">
      <template #input>
        <van-radio-group :value="value" direction="horizontal" class="margin3">
          <van-radio v-for="item,index in columns" checked-color="#67C23A" :disabled="disabled" :key="index" @click="onConfirm(item[valueKey])" :name="item[valueKey]">{{item[labelKey]}}</van-radio>
        </van-radio-group>
      </template>
    </van-field>
  </div>
</template>

<script>
export default {
  name: "customSelect",
  props: {
    labelKey: {
      // label显示显示字段名称
      type: String,
      default: "label",
    },
    valueKey: {
      // 后台储存参数字段名称
      type: String,
      default: "value",
    },
    placeholder: {
      // 文本提示
      type: String,
      default: "请选择",
    },
    value: {
      // v-model
      type: Object | Number | Boolean | String,
      default: "",
    },
    label: {
      // 表单
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    columns: {
      // 字典数据
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    onConfirm(value) {
      if (!this.disabled) {
        this.$emit("input", value);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.van-cell {
  font-size: 15px;
}
</style>
