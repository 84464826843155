

/**
 * @description 格式化时间
 * @param time
 * @param cFormat
 * @returns {string|null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  return format.replace(/{([ymdhisa])+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
}

export function rowToForm(data) {
  return JSON.parse(JSON.stringify(data))
}

// 将富文本内容专程base64编码，这个用于上传到服务存储到数据库中
export function encode(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
    function toSolidBytes(match, p1) {
      return String.fromCharCode('0x' + p1)
    }))
}
// 将富文本的base64编码 转换成原来的格式，这个用于将数据库中的富文本展示在界面上
export function decode(str) {
  return decodeURIComponent(atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

/**
 * @description 格式化时间
 * @param time
 * @param option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @description 将url请求参数转为json格式
 * @param url
 * @returns {{}|any}
 */
export function paramObj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/\+/g, ' ') +
    '"}'
  )
}

/**
 * @description 父子关系的数组转换成树形结构数据
 * @param data
 * @returns {*}
 */
export function translateDataToTree(data) {
  const parent = data.filter(
    (value) => value.parentId === 'undefined' || value.parentId === null
  )
  const children = data.filter(
    (value) => value.parentId !== 'undefined' && value.parentId !== null
  )
  const translator = (parent, children) => {
    parent.forEach((parent) => {
      children.forEach((current, index) => {
        if (current.parentId === parent.id) {
          const temp = JSON.parse(JSON.stringify(children))
          temp.splice(index, 1)
          translator([current], temp)
          typeof parent.children !== 'undefined'
            ? parent.children.push(current)
            : (parent.children = [current])
        }
      })
    })
  }
  translator(parent, children)
  return parent
}

/**
 * @description 树形结构数据转换成父子关系的数组
 * @param data
 * @returns {[]}
 */
export function translateTreeToData(data) {
  const result = []
  data.forEach((item) => {
    const loop = (data) => {
      result.push({
        id: data.id,
        name: data.name,
        parentId: data.parentId,
      })
      const child = data.children
      if (child) {
        for (let i = 0; i < child.length; i++) {
          loop(child[i])
        }
      }
    }
    loop(item)
  })
  return result
}

/**
 * @description 10位时间戳转换
 * @param time
 * @returns {string}
 */
export function tenBitTimestamp(time) {
  const date = new Date(time * 1000)
  const y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? '' + m : m
  let d = date.getDate()
  d = d < 10 ? '' + d : d
  let h = date.getHours()
  h = h < 10 ? '0' + h : h
  let minute = date.getMinutes()
  let second = date.getSeconds()
  minute = minute < 10 ? '0' + minute : minute
  second = second < 10 ? '0' + second : second
  return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second //组合
}

/**
 * @description 13位时间戳转换
 * @param time
 * @returns {string}
 */
export function thirteenBitTimestamp(time) {
  const date = new Date(time / 1)
  const y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? '' + m : m
  let d = date.getDate()
  d = d < 10 ? '' + d : d
  let h = date.getHours()
  h = h < 10 ? '0' + h : h
  let minute = date.getMinutes()
  let second = date.getSeconds()
  minute = minute < 10 ? '0' + minute : minute
  second = second < 10 ? '0' + second : second
  return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second //组合
}

/**
 * @description 获取随机id
 * @param length
 * @returns {string}
 */
export function uuid(length = 32) {
  const num = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
  let str = ''
  for (let i = 0; i < length; i++) {
    str += num.charAt(Math.floor(Math.random() * num.length))
  }
  return str
}

/**
 * @description m到n的随机数
 * @param m
 * @param n
 * @returns {number}
 */
export function random(m, n) {
  return Math.floor(Math.random() * (m - n) + n)
}

/**
 * @description addEventListener
 * @type {function(...[*]=)}
 */
export const on = (function () {
  return function (element, event, handler, useCapture = false) {
    if (element && event && handler) {
      element.addEventListener(event, handler, useCapture)
    }
  }
})()

/**
 * @description removeEventListener
 * @type {function(...[*]=)}
 */
export const off = (function () {
  return function (element, event, handler, useCapture = false) {
    if (element && event) {
      element.removeEventListener(event, handler, useCapture)
    }
  }
})()

/**
 * @description 数组打乱
 * @param array
 * @returns {*}
 */
export function shuffle(array) {
  let m = array.length,
    t,
    i
  while (m) {
    i = Math.floor(Math.random() * m--)
    t = array[m]
    array[m] = array[i]
    array[i] = t
  }
  return array
}

// 深拷贝对象
export function deepClone(obj) {
  const _toString = Object.prototype.toString

  // null, undefined, non-object, function
  if (!obj || typeof obj !== 'object') {
    return obj
  }

  // DOM Node
  if (obj.nodeType && 'cloneNode' in obj) {
    return obj.cloneNode(true)
  }

  // Date
  if (_toString.call(obj) === '[object Date]') {
    return new Date(obj.getTime())
  }

  // RegExp
  if (_toString.call(obj) === '[object RegExp]') {
    const flags = []
    if (obj.global) { flags.push('g') }
    if (obj.multiline) { flags.push('m') }
    if (obj.ignoreCase) { flags.push('i') }

    return new RegExp(obj.source, flags.join(''))
  }

  const result = Array.isArray(obj) ? [] : obj.constructor ? new obj.constructor() : {}

  for (const key in obj) {
    result[key] = deepClone(obj[key])
  }

  return result
}

/**
 * 判断当前时间是否处于某个时间段内
 *  传入参数为yyyy-mm-dd
 * beginDateStr 开始时间 endDateStr 结束时间
 */
export function isDuringDate(beginDateStr, endDateStr) {
  const curDate = new Date(),
    beginDate = new Date(beginDateStr),
    enDate = new Date(endDateStr).setDate(new Date(endDateStr).getDate() + 1);
  if (curDate >= beginDate && curDate <= enDate) {
    return true
  }
  return false
}


/**     * 得到当前学期年 返回格式:yyyy
 * @return String
 */
export function getTermYear(xqs, month) {
  if (!month) {
    month = new Date().getMonth() + 1;
  }
  if (month < 10) { month = '0' + month }
  let current = null
  xqs.forEach(item => {
    if (item.termList.indexOf(month) > -1) {
      current = item;
    }
  })
  if (current == null) {
    this.$message.error("数据字典学期月份配置错误！");
    return;
  }
  let year = parseInt(new Date().getFullYear().toString());
  return month === '01' || month === '02' ? (year - 1) + "" : year + "";
}

/**
 * 得到当前学期代码与名称 返回格式:Map<String, String>
 *
 * @return xqCode ：学期代码 xqName：学期名称
 */
export function getTerm(xqs, month) {
  let map = {}
  if (!month) {
    month = new Date().getMonth() + 1;
  }
  if (month < 10) { month = '0' + month }
  xqs.forEach((item) => {
    if (item.termList.indexOf(month) > -1) {
      map = { xqCode: item.value, xqName: item.label }
    }
  })
  return map;
}

/**
 * 根据审批状态获取Tag 的图标类型 
 * @param Number 审批状态
 * @return String
 */
export function tagStatusType(status) {
  status = parseInt(status)
  if ([1, 2, 3, 5].indexOf(status) > -1) {
    return 'info'
  }
  if (status === 9) return 'success'
  if ([101, 201, 301, 401, 501].indexOf(status) > -1) {
    return 'error'
  }
  if ([8, 108, 208, 308, 408, 508]) {
    return 'warning'
  }
  return 'info'
}

/**
 * 根据字典数据获取label值
 * @param code 字典数据编码
 * @param dicts 字典数据
 * @return String
 */
export function codeConvertLabel(code, dicts) {
  return dicts
    .filter((item) => {
      return item.value + "" === code + "";
    })
    .map((item) => {
      return item.label;
    })
    .toString();
}

export function validCustom(fields, _this) {
  let arr = [];
  fields.forEach((element) => {
    arr.push(_this.$refs[element].validate());
  });
  return arr.includes(false) ? false : true;
}

export function statustag(shzt, type) {
  let success = [9];
  let error = [101, 201, 301, 401, 501, 601, 701, 801, 901, 1001];
  let info = [0, 1, 2, 3, 4, 5, 6, -1, 7, 8, 9];
  let warning = [108, 208, 308, 408, 508, 608, 708, 808, 908, 1008];
  let status = parseInt(shzt);
  if (type === "success") {
    if (success.indexOf(status) > -1) {
      return true;
    }
  } else if (type === "error") {
    if (error.indexOf(status) > -1) {
      return true;
    }
  } else if (type === "warning") {
    if (warning.indexOf(status) > -1) {
      return true;
    }
  } else if (type === "info") {
    if (info.indexOf(status) > -1) {
      return true;
    }
  }
  return false;
}

/**
 * 该方法用于路由地址鉴权
 * @param {*} router 
 * @param {*} user 
 * @returns 
 */
export function permissionAuth(router, user) {
  // 获取角色
  let role = user.roles
  let roleIds = role.map((e) => e.id)
  // 方向所有的详情表单页面
  if (router.path.endsWith("form") || router.path.endsWith("edit") || router.path.endsWith("add") || router.path.endsWith("detail") || router.path.endsWith("current")) {
    return true
  } else {
    if (router.meta.auth && router.meta.auth.length > 0) {
      // 如果配置了权限
      let authData = router.meta.auth
      let res = false;
      roleIds.forEach((id) => {
        if (authData.indexOf(id) > -1) {
          res = true;
        }
      })
      return res;
    } else {
      return false;
    }
  }
}
