import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routeList = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    component: () => import('@/view/index'),
    meta: {
      title: '主页'
    }
  },
  {
    path: '/401',
    component: () => import('@/view/system/401'),
    meta: {
      title: '认证失败'
    }
  },
  {
    path: '/402',
    component: () => import('@/view/system/402'),
    meta: {
      title: '无权访问'
    }
  },
  {
    path: '/403',
    component: () => import('@/view/system/403'),
    meta: {
      title: '未检测到账号'
    }
  },
  {  //学生个人信息维护
    path: '/xs/a01',
    component: () => import('@/view/modules/student/personal/info'),
    meta: { title: '个人信息维护', auth: [6] }
  },
  {// 学生 获奖信息维护
    path: '/xs/a02',
    component: () => import('@/view/modules/student/personal/award/index'),
    meta: { title: '获奖信息维护', auth: [6] }
  },
  {
    path: '/xs/a02/form',
    component: () => import('@/view/modules/student/personal/award/form'),
    meta: { title: '获奖信息维护' }
  },
  {
    path: '/xs/a02/detail',
    component: () => import('@/view/modules/student/personal/award/detail'),
    meta: { title: '获奖详情' }
  },// 学生 获奖信息维护
  {
    path: '/xs/a03',
    component: () => import('@/view/modules/student/family/index'),
    meta: { title: '家庭情况调查表', auth: [6] }
  },
  {
    path: '/xs/a03/form',
    component: () => import('@/view/modules/student/family/form'),
    meta: { title: '家庭情况调查表' }
  },
  {
    path: '/xs/a03/detail',
    component: () => import('@/view/modules/student/family/detail'),
    meta: { title: '家庭情况调查表详情' }
  },
  // 学生 贷款
  {
    path: '/xs/a04',
    component: () => import('@/view/modules/student/loan/index.vue'),
    meta: { title: '贷款申请', auth: [6] }
  },
  {
    path: '/xs/a04/form',
    component: () => import('@/view/modules/student/loan/form'),
    meta: { title: '贷款申请' }
  },
  {
    path: '/xs/a04/detail',
    component: () => import('@/view/modules/student/loan/detail'),
    meta: { title: '贷款申请' }
  },
  // 奖助申请
  {
    path: '/xs/a05',
    component: () => import('@/view/modules/student/award/index'),
    meta: { title: '奖助申请', auth: [6] }
  },
  {
    path: '/xs/a05/project/detail',
    component: () => import('@/view/modules/student/award/project/detail'),
    meta: { title: '项目详情' }
  },
  {
    path: '/xs/a05/10001/form',
    component: () => import('@/view/modules/student/award/bonus/inspirational/index'),
    meta: { title: '国家励志奖学金申请' }
  },
  {
    path: '/xs/a05/10001/detail',
    component: () => import('@/view/modules/student/award/bonus/inspirational/detail'),
    meta: { title: '国家励志奖学金详情' }
  },
  {
    path: '/xs/a05/10002/form',
    component: () => import('@/view/modules/student/award/bonus/excellent/index'),
    meta: { title: '优秀学生奖学金申请' }
  },
  {
    path: '/xs/a05/10002/detail',
    component: () => import('@/view/modules/student/award/bonus/excellent/detail'),
    meta: { title: '优秀学生奖学金详情' }
  },
  {
    path: '/xs/a05/10003/form',
    component: () => import('@/view/modules/student/award/bonus/nation/index'),
    meta: { title: '国家奖学金申请' }
  },
  {
    path: '/xs/a05/10003/detail',
    component: () => import('@/view/modules/student/award/bonus/nation/detail'),
    meta: { title: '国家奖学金详情' }
  },
  {
    path: '/xs/a05/20001/form',
    component: () => import('@/view/modules/student/award/help/nation/index'),
    meta: { title: '国家助学金申请' }
  },
  {
    path: '/xs/a05/20001/detail',
    component: () => import('@/view/modules/student/award/help/nation/detail'),
    meta: { title: '国家助学金详情' }
  },
  {
    path: '/xs/a05/20002/form',
    component: () => import('@/view/modules/student/award/help/tks/index'),
    meta: { title: '特困生助学金申请' }
  },
  {
    path: '/xs/a05/20002/detail',
    component: () => import('@/view/modules/student/award/help/tks/detail'),
    meta: { title: '特困生助学金详情' }
  },
  // 公寓
  {
    path: '/gy/g01',
    component: () => import('@/view/modules/apartment/floor/index'),
    meta: { title: '学生入住信息查询' }
  },
  {
    path: '/gy/g02',
    component: () => import('@/view/modules/apartment/layer/index'),
    meta: { title: '学生入住信息查询', auth: [6] }
  },
  {
    path: '/gy/g03',
    component: () => import('@/view/modules/apartment/room/index'),
    meta: { title: '学生入住信息查询', auth: [6] }
  },
  {
    path: '/gy/g04',
    component: () => import('@/view/modules/apartment/into/index'),
    meta: { title: '学生入住信息查询', auth: [6] }
  },
  {
    path: '/xs/sign',
    component: () => import('@/view/modules/student/sign/index'),
    meta: { title: '公寓晚签', auth: [6] }
  },
  {
    path: '/js/poverty',
    component: () => import('@/view/modules/teacher/poverty/index'),
    meta: { title: '贫困生认定', auth: [3, 4, 5] }
  },
  {
    path: '/js/stu/detail',
    component: () => import('@/view/modules/teacher/stu/personal'),
    meta: { title: '学生个人主页' }
  },
  {
    path: '/js/stu/award',
    component: () => import('@/view/modules/teacher/award/index'),
    meta: { title: '学生获奖审核', auth: [3, 4, 5] }
  },
  {
    path: '/js/loan/school',
    component: () => import('@/view/modules/teacher/loan/school'),
    meta: { title: '校园地贷款', auth: [3, 4, 5] }
  },
  {
    path: '/js/loan/bron',
    component: () => import('@/view/modules/teacher/loan/bron'),
    meta: { title: '生源地贷款', auth: [3, 4, 5] }
  },
  {
    path: '/js/stuInfo/info',
    component: () => import('@/view/modules/teacher/stu/info'),
    meta: { title: '学生信息管理', auth: [3, 4, 5] }
  },
  {
    path: '/js/stuInfo/expand',
    component: () => import('@/view/modules/teacher/stu/info'),
    meta: { title: '扩招生管理', auth: [3, 4, 5] }
  },
  {
    path: '/js/stuInfo/graduate',
    component: () => import('@/view/modules/teacher/stu/info'),
    meta: { title: '毕业生信息管理', auth: [3, 4, 5] }
  },
  {
    path: '/js/stuInfo/class/form',
    component: () => import('@/view/modules/teacher/stu/classSetting'),
    meta: { title: '班级配置' }
  },
  {
    path: '/js/jz/national',
    component: () => import('@/view/modules/teacher/assistantship/bonus/national'),
    meta: { title: '国家奖学金', auth: [3, 4, 5] }
  },
  {
    path: '/js/jz/imp',
    component: () => import('@/view/modules/teacher/assistantship/bonus/improvement'),
    meta: { title: '国家励志奖学金', auth: [3, 4, 5] }
  },
  {
    path: '/js/jz/excellent',
    component: () => import('@/view/modules/teacher/assistantship/bonus/excellent'),
    meta: { title: '优秀学生奖学金', auth: [3, 4, 5] }
  },
  {
    path: '/js/jz/coustom',
    component: () => import('@/view/modules/teacher/assistantship/bonus/coustom'),
    meta: { title: '自定义奖学金', auth: [3, 4, 5] }
  },
  {
    path: '/js/zxj/national',
    component: () => import('@/view/modules/teacher/assistantship/help/national'),
    meta: { title: '国家助学金', auth: [3, 4, 5] }
  },
  {
    path: '/js/zxj/poverty',
    component: () => import('@/view/modules/teacher/assistantship/help/poverty'),
    meta: { title: '特困生助学金', auth: [3, 4, 5] }
  },
  {
    path: '/js/zxj/coustom',
    component: () => import('@/view/modules/teacher/assistantship/help/coustom'),
    meta: { title: '自定义助学金', auth: [3, 4, 5] }
  },
  {
    path: '/xc/jz/project',
    component: () => import('@/view/modules/manage/awardProject/index'),
    meta: { title: '奖助项目管理', auth: [3] }
  },
  {
    path: '/xsc/jz/project/detail',
    component: () => import('@/view/modules/manage/awardProject/detail'),
    meta: { title: '项目详情' }
  },
  {
    path: '/xsc/jz/project/form',
    component: () => import('@/view/modules/manage/awardProject/form'),
    meta: { title: '项目编辑' }
  },
  {
    path: '/js/leave/school',
    component: () => import('@/view/modules/teacher/leave/index'),
    meta: { title: '离校管理', auth: [5] }
  },
  {
    path: '/js/leave/school/form',
    component: () => import('@/view/modules/teacher/leave/form'),
    meta: { title: '离校编辑' }
  },
  {
    path: '/js/leave/school/detail',
    component: () => import('@/view/modules/teacher/leave/detail'),
    meta: { title: '离校数据详情' }
  },
  {
    path: '/xs/pay/report',
    component: () => import('@/view/modules/student/payReport/index'),
    meta: { title: '学费缴纳上报', auth: [6] }
  },
  {
    path: '/js/pay/report/detail',
    component: () => import('@/view/modules/student/payReport/detail'),
    meta: { title: '学费缴纳详情' }
  },
  {
    path: '/js/pay/report/form',
    component: () => import('@/view/modules/student/payReport/form'),
    meta: { title: '学费缴纳上报' }
  },
  {
    path: '/xs/stu/card',
    component: () => import('@/view/modules/student/stuCard/index'),
    meta: { title: '学生证补办', auth: [6] }
  },
  {
    path: '/js/stu/card/detail',
    component: () => import('@/view/modules/student/stuCard/detail'),
    meta: { title: '学生证补办详情' }
  },
  {
    path: '/js/stu/card/form',
    component: () => import('@/view/modules/student/stuCard/form'),
    meta: { title: '学生证补办申请' }
  },
  {
    path: '/js/pay/report',
    component: () => import('@/view/modules/teacher/payReport/index'),
    meta: { title: '学费缴纳', auth: [3, 4, 5] }
  },
  {
    path: '/js/stu/card',
    component: () => import('@/view/modules/teacher/stuCard/index'),
    meta: { title: '学生证补办', auth: [3, 4, 5, 9] }
  },
  {
    path: '/xs/disciplinary',
    component: () => import('@/view/modules/student/disciplinary/index'),
    meta: { title: '我的违纪', auth: [6] }
  },
  {
    path: '/xs/disciplinary/detail',
    component: () => import('@/view/modules/student/disciplinary/detail'),
    meta: { title: '违纪信息详情' }
  },
  {
    path: '/xs/disciplinary/cancel/form',
    component: () => import('@/view/modules/student/disciplinary/cancel'),
    meta: { title: '违纪撤销申请' }
  },
  {
    path: '/js/disciplinary',
    component: () => import('@/view/modules/teacher/disciplinary/index'),
    meta: { title: '学生违纪', auth: [3, 4, 5] }
  },
  {
    path: '/js/disciplinary/cancel',
    component: () => import('@/view/modules/teacher/disciplinary/cancel'),
    meta: { title: '学生违纪撤销' }
  },
  {
    path: '/js/disciplinary/form',
    component: () => import('@/view/modules/teacher/disciplinary/form'),
    meta: { title: '学生违纪发起' }
  },
  {
    path: '/js/disciplinary/detail',
    component: () => import('@/view/modules/teacher/disciplinary/detail'),
    meta: { title: '违纪信息详情' }
  },
  {
    path: '/js/job/setting',
    component: () => import('@/view/modules/teacher/diligentJob/index'),
    meta: { title: '设岗管理', auth: [3, 4] }
  },
  {
    path: '/js/job/setting/form',
    component: () => import('@/view/modules/teacher/diligentJob/form'),
    meta: { title: '岗位上报' }
  },
  {
    path: '/js/job/setting/detail',
    component: () => import('@/view/modules/teacher/diligentJob/detail'),
    meta: { title: '岗位详情' }
  },
  {
    path: '/xs/stuJobApply',
    component: () => import('@/view/modules/student/stuJobApply/index'),
    meta: { title: '勤工助学', auth: [6] }
  },
  {
    path: '/xs/stuJobApply/form',
    component: () => import('@/view/modules/student/stuJobApply/form'),
    meta: { title: '勤工助学申请' }
  },
  {
    path: '/xs/stuJobApply/detail',
    component: () => import('@/view/modules/student/stuJobApply/detail'),
    meta: { title: '勤工助学详情' }
  },
  {
    path: '/js/stuJobApply',
    component: () => import('@/view/modules/teacher/diligentJob/applayJob/index'),
    meta: { title: '勤工助学', auth: [3, 4, 5, 10] }
  },
  {
    path: '/xc/achievement',
    component: () => import('@/view/modules/teacher/achievement/index'),
    meta: { title: '学生成绩', auth: [3] }
  },
  {
    path: '/xc/achievement/detail',
    component: () => import('@/view/modules/teacher/achievement/detail'),
    meta: { title: '成绩详情' }
  },
  {
    path: '/xs/comprehensive/attendance',
    component: () => import('@/view/modules/student/comprehensive/attendance/index'),
    meta: { title: '操勤分', auth: [5, 6] }
  },
  {
    path: '/xs/comprehensive/attendance/form',
    component: () => import('@/view/modules/student/comprehensive/attendance/form'),
    meta: { title: '操勤分发起' }
  },
  {
    path: '/xs/comprehensive/attendance/detail',
    component: () => import('@/view/modules/student/comprehensive/attendance/detail'),
    meta: { title: '操勤分详情' }
  },
  {
    path: '/xc/comprehensive/attendance',
    component: () => import('@/view/modules/teacher/comprehensive/attendance/index'),
    meta: { title: '操勤分管理', auth: [3, 4, 11] }
  },
  {
    path: '/xs/attendance/check/form',
    component: () => import('@/view/modules/teacher/comprehensive/attendance/score'),
    meta: { title: '操勤分打分' }
  },
  {
    path: '/xs/comprehensive/cultivate',
    component: () => import('@/view/modules/student/comprehensive/fiveEdu/index'),
    meta: { title: '五育实践', auth: [5, 6] }
  },
  {
    path: '/xs/comprehensive/cultivate/form',
    component: () => import('@/view/modules/student/comprehensive/fiveEdu/form'),
    meta: { title: '五育实践发起' }
  },
  {
    path: '/xs/comprehensive/cultivate/detail',
    component: () => import('@/view/modules/student/comprehensive/fiveEdu/detail'),
    meta: { title: '五育实践详情' }
  },
  {
    path: '/xc/comprehensive/cultivate',
    component: () => import('@/view/modules/teacher/comprehensive/fiveEdu/index'),
    meta: { title: '五育实践管理', auth: [3, 4, 11] }
  },
  {
    path: '/js/addSubtract',
    component: () => import('@/view/modules/teacher/comprehensive/addSubtract/index'),
    meta: { title: '加减分项', auth: [5] }
  },
  {
    path: '/js/addSubtract/form',
    component: () => import('@/view/modules/teacher/comprehensive/addSubtract/form'),
    meta: { title: '加减分项发起' }
  },
  {
    path: '/js/addSubtract/detail',
    component: () => import('@/view/modules/teacher/comprehensive/addSubtract/detail'),
    meta: { title: '加减分项详情' }
  },
  {
    path: '/xc/caddSubtract',
    component: () => import('@/view/modules/teacher/comprehensive/addSubtract/audit'),
    meta: { title: '加减分项管理', auth: [4, 11] }
  },
  {
    path: '/js/special/addSubtract',
    component: () => import('@/view/modules/teacher/comprehensive/specialAddSubtract/index'),
    meta: { title: '特殊加减分项', auth: [5] }
  },
  {
    path: '/js/special/addSubtract/form',
    component: () => import('@/view/modules/teacher/comprehensive/specialAddSubtract/form'),
    meta: { title: '特殊加减分项发起' }
  },
  {
    path: '/js/special/addSubtract/detail',
    component: () => import('@/view/modules/teacher/comprehensive/specialAddSubtract/detail'),
    meta: { title: '特殊加减分项详情' }
  },
  {
    path: '/xc/special/caddSubtract',
    component: () => import('@/view/modules/teacher/comprehensive/specialAddSubtract/audit'),
    meta: { title: '特殊加减分项', auth: [4, 11] }
  },
  {
    path: '/xc/score/manage',
    component: () => import('@/view/modules/teacher/comprehensive/sum/index'),
    meta: { title: '综测成绩管理', auth: [3, 4, 5] }
  },
  {
    path: '/xc/score/manage/detail',
    component: () => import('@/view/modules/teacher/comprehensive/sum/detail'),
    meta: { title: '综测成绩详情' }
  },
  {
    path: '/xs/question',
    component: () => import('@/view/modules/student/question/index'),
    meta: { title: '问卷调查', auth: [6] }
  },
  {
    path: '/xs/question/detail',
    component: () => import('@/view/modules/student/question/detail'),
    meta: { title: '问卷调查' }
  },
  {
    path: '/xs/question/form',
    component: () => import('@/view/modules/student/question/form'),
    meta: { title: '问卷调查' }
  },
  {
    path: '/xc/face/recognition/current',
    component: () => import('@/view/modules/teacher/faceRecognition/index'),
    meta: { title: '人脸识别' }
  },
];

const router = new Router({
  // mode: 'hash',
  mode: 'history',
  base: process.env.NODE_ENV === 'development' ? '' : '',
  scrollBehavior: () => ({ y: 0 }),
  routes: routeList
})

export default router
