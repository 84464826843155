<template>
  <div id="statusTagIndex">
    <span class="vab-dot" :class="getClass()"><span></span></span>
    <span class="tag-label">
      <slot></slot>
    </span>
  </div>
</template>

<script>
/**
 * 自定义标签
 * @author GavinShaw
 *
 * props:
 *    type  类型 info | primary | success | warning | error
 */

export default {
  name: "statusTagIndex",
  props: {
    type: {
      type: String,
      default: "info",
    },
  },
  data() {
    return {
      typeList: ["info", "primary", "success", "warning", "error"],
    };
  },
  methods: {
    getClass() {
      const typeIndex = this.typeList.indexOf(this.type);
      if (typeIndex < 0) return "vab-dot-" + this.typeList[0];
      return "vab-dot-" + this.type;
    },
  },
};
</script>

<style lang="css" scoped>
#statusTagIndex {
  vertical-align: top;
  font-size: 13px;
}
.vab-dot {
  width: 8px !important;
  height: 8px !important;
  margin-top: -3px;
}
.tag-label {
  margin-left: 5px;
  display: inline-block;
  vertical-align: top;
}
</style>
