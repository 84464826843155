import Vue from 'vue';
import App from './App';
import router from './router/index';
import Vant from 'vant/es'
import 'vant/lib/index.css'
import store from './store'
import { Notify, Dialog } from 'vant';
import dict from '@/components/Dict/index'
import '@/assets/styles/index.scss'
import '@/utils/permissions'
import '@/assets/icons/index';
import { Toast } from 'vant';

Vue.use(Toast);
// 全局注册
Vue.use(Vant)

// 通知提示
Vue.use(Dialog);

// 弹框提示
Vue.use(Notify);

// 数据字典
Vue.use(dict)

// 自定义Radio
import customRadio from '@/components/CustomRadio/index'
Vue.component('CustomRadio', customRadio)
// 自定义年月日选择器
import customDatePicker from '@/components/CustomDatePicker/index'
Vue.component('CustomDatePicker', customDatePicker)
// 自定义单选选择器
import customSelect from '@/components/CustomSelect/index'
Vue.component('CustomSelect', customSelect)
// 自定义省市区联级
import regionalCascader from '@/components/RegionalCascader/index'
Vue.component('RegionalCascader', regionalCascader)
// 自定义tag
import StatusTag from "@/components/StatusTag";
Vue.component("StatusTag", StatusTag);
// 自定义切换角色
import switchRole from '@/components/SwitchRole/index'
Vue.component('SwitchRole', switchRole)

new Vue({
  store,
  router,
  el: '#app',
  render: h => h(App)
});
