<template>
  <div class="border-line">
    <van-field :required="required" readonly clickable :disabled="disabled" name="picker" :value="value" right-icon="notes-o" :label="label" @click="openPicker">
      <template #input>
        <span v-if="value && showLabel" :style="disabled?'color:#c8c9cc;font-size: 14px;':''">{{showLabel}}</span>
        <span v-else-if="!vaildTitle" style="color:#C0C4CC;font-size: 14px;">{{placeholder}}</span>
        <span v-else-if="vaildTitle" style="color:#FF7577;font-size: 14px;">{{vaildTitle}}</span>
      </template>
    </van-field>
    <van-popup v-model="showPicker" position="bottom" round :style="{ height: '40%' }">
      <van-datetime-picker type="date" v-model="currentDate" :min-date="minDate" :max-date="maxDate" title="选择年月日" @confirm="onConfirm" @cancel="showPicker = false" />
    </van-popup>
  </div>
</template>

<script>
import { parseTime } from "@/utils";

export default {
  name: "customDatePicker",
  props: {
    placeholder: {
      type: String,
      default: "请选择",
    },
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(val) {
      if (val) {
        let str = parseTime(val, "{y}-{m}-{d}");
        let arr = str.split("-");
        if (str && arr.length === 3) {
          this.currentDate = new Date(
            parseInt(arr[0]),
            parseInt(arr[1]) - 1,
            parseInt(arr[2])
          );
        }
        this.showLabel = str;
        return str;
      }
      return null;
    },
  },
  data() {
    return {
      showLabel: "",
      showPicker: false,
      minDate: new Date(1970, 1, 1),
      maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
      currentDate: new Date(),
      vaildTitle: "",
    };
  },
  methods: {
    parseTime,
    onConfirm(value) {
      if (!this.disabled) {
        this.vaildTitle = "";
        this.$emit("input", parseTime(value, "{y}-{m}-{d}"));
        this.$emit("change", parseTime(value, "{y}-{m}-{d}"));
        this.showPicker = false;
      }
    },
    openPicker() {
      if (!this.disabled) {
        this.showPicker = true;
      }
    },
    validate() {
      if (this.value && this.showLabel) {
        return true;
      } else {
        this.vaildTitle = "请选择" + this.label;
        return false;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.van-cell {
  font-size: 15px;
}
</style>