<template>
  <div class="border-line">
    <van-field :required="required" :readonly="readonly" clickable :disabled="disabled" :value="value" v-bind="$attrs" :right-icon="showPicker?'arrow-up':'arrow-down'" :label="label" @click="clickShowPicker">
      <template #input>
        <span v-if="value && showLabel" :style="disabled?'color:#c8c9cc;font-size: 14px;':''">{{showLabel}}</span>
        <span v-else-if="!vaildTitle" style="color:#C0C4CC;font-size: 14px;">{{placeholder}}</span>
        <span v-else-if="vaildTitle" style="color:#FF7577;font-size: 14px;">{{vaildTitle}}</span>
      </template>
    </van-field>
    <van-popup v-model="showPicker" position="bottom" round :style="{ height: '40%' }">
      <van-picker show-toolbar :columns="columns" :default-index="defaultIndex" :value-key="labelKey" @confirm="onConfirm" @cancel="showPicker = false" />
    </van-popup>
  </div>
</template>

<script>
/**
 *  若是数据不回显请检查 valueKey 配置
 * 
 */
export default {
  name: "customSelect",
  props: {
    labelKey: {
      // label显示显示字段名称
      type: String,
      default: "label",
    },
    valueKey: {
      // 后台储存参数字段名称
      type: String,
      default: "value",
    },
    placeholder: {
      // 文本提示
      type: String,
      default: "请选择",
    },
    value: {
      // v-model
      type: Object | Number | Boolean | String,
      default: "",
    },
    label: {
      // 表单
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    columns: {
      // 字典数据
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPicker: false,
      showLabel: "",
      vaildTitle: "",
    };
  },
  computed: {
    defaultIndex() {
      let checked = 0;
      this.columns.forEach((item, index) => {
        if (item[this.valueKey] + "" === this.value + "") {
          this.showLabel = item[this.labelKey];
          checked = index;
          return;
        }
      });
      return checked;
    },
  },
  methods: {
    onConfirm(value) {
      if (!this.disabled && !this.readonly) {
        this.vaildTitle = "";
        this.showLabel = value[this.labelKey];
        this.$emit("input", value[this.valueKey]);
        this.$emit("change", value[this.valueKey], value);
        this.showPicker = false;
      }
    },
    clickShowPicker() {
      if (!this.disabled) {
        this.showPicker = true;
      }
    },
    validate() {
      if (this.value && this.showLabel) {
        return true;
      } else {
        this.vaildTitle = "请选择" + this.label;
        return false;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.van-cell {
  font-size: 15px;
}
</style>

