import request from '@/utils/request'

/**
* 获取院系
* @returns  deptCode 编码  name 名称
*/
export function getFacultys() {
  let params = { pid: 12 ,enabled:true,deptCodeNotIn:[1200]};
  return request({
    url: 'api/dept/noPage',
    method: 'get',
    params
  })
}

/**
 * 根据院系编码获取专业 
 * @returns specCode 专业码  specName 名称 unitNo 所属院系code  unitName院系名称
 */
export function getSpecInfoByDeptCode(deptCode) {
  let params = { unitNo: deptCode };
  return request({
    url: 'api/specSubjectInfo/noPage',
    method: 'get',
    params
  })
}

// 扩招生班级
export function getSpecNoPageByExpand(deptCode) {
  let params = { unitNo: deptCode };
  return request({
    url: 'api/specSubjectInfo/noPageByExpand',
    method: 'get',
    params
  })
}

/**
 * 根据专业编码获取班级
 * @returns classNo 班级编号 className 编辑名称 unitNo 院系编号
 */
export function getClassInfoBySpecCode(specCode) {
  let params = { specCode: specCode };
  return request({
    url: '/api/xg/classInfo/noPage',
    method: 'get',
    params
  })
}


/**
 * 获取专业数据
 * @returns specCode 专业码  specName 名称 unitNo 所属院系code  unitName院系名称
 */
export function getSpecInfos() {
  return request({
    url: 'api/specSubjectInfo/noPage',
    method: 'get',
  })
}


/**
 * 获取省份list
 * @returns lable 名称 value 区域码 id pid
 */
export function getProvinceInfo() {
  let params = { level: 1 };
  return request({
    url: 'api/sysProvinceInfo/noPage',
    method: 'get',
    params
  })
}


/**
 * 根据上一级区域ID 获取子级区域码
 * @returns lable 名称  value 区域码 id pid
 */
export function getProvinceChilds(id) {
  let params = { pid: id };
  return request({
    url: 'api/sysProvinceInfo/noPage',
    method: 'get',
    params
  })
}


/**
 * 获取省市区联级树
 * @returns lable 名称  value 区域码 id pid 父节点 children 子数据
 */
export function getProvinceTree(params) {
  return request({
    url: 'api/sysProvinceInfo/getTree',
    method: 'get',
    params
  })
}

/**
 * 获取省市联动树
 * @returns lable 名称  value 区域码 id pid 父节点 children 子数据
 */
export function getProvinceAndCityTree(params) {
  return request({
    url: 'api/sysProvinceInfo/getTreeOfCity',
    method: 'get',
    params
  })
}

export function convertProvinceName(params) {
  return request({
    url: 'api/sysProvinceInfo/convert',
    method: 'get',
    params
  })
}


export default { getFacultys, getSpecInfos, getSpecInfoByDeptCode, getProvinceInfo,getSpecNoPageByExpand, getProvinceChilds, getProvinceTree, getClassInfoBySpecCode,getProvinceAndCityTree ,convertProvinceName}