<template>
  <div id="SwitchRole">
    <div ref="floatWindow" class="role-container" v-if="showSuspended" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd" :style="{left: left + 'px', top: top + 'px'}">
      <van-sticky :container="container">
        <van-button class="back" round :hairline="true" @click="showDialogChangeUser = !showDialogChangeUser" />
      </van-sticky>
    </div>
    <van-dialog v-model="showDialogChangeUser" title="" show-cancel-button :showCancelButton="false" :beforeClose="handleClick">
      <div class="slot-content">
        <div :class="'text-box  '+(active===index?'change':'default')" @click="clickUnclocked(index,item)" v-for="(item,index) in roleList" :key="index">
          <van-icon :name="item.dataScope==='自身' ? require('@/assets/icon/fdy.png'):require('@/assets/icon/fsj.png')" size="23" style="margin:0 8px 0 0" />{{ item.deptName}}-{{ item.roleName }}
          <div class="role-tag"></div>
        </div>
      </div>
      <template #title>
        选择切换角色 <van-icon name="cross" class="title" @click="closeChangeRole" />
      </template>
    </van-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { changeLogin } from '@/api/authorization'
import store from '@/store'


export default {
  name: "SwitchRole",
  data() {
    return {
      container: null,
      startX: 0,
      startY: 0,
      offsetX: 0,
      offsetY: 0,
      left: 0,
      top: 0,
      showDialogChangeUser: false,
      active: 0,
      showSuspended: true,
      roleList: [],
      activeUser: {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      roles: 'roles',
      token: 'token'
    }),
  },
  created() {
    let height = window.innerHeight
    this.top = (height / 6) * 5
    let width = window.innerWidth
    this.left = width - 60

    this.initRoles()
  },
  methods: {
    initRoles() {
      let roles = []
      this.active = 0

      // 默认角色
      let roleDefault = {
        nickName: this.user.nickName,
        roles: this.user.roles,
        id: this.user.id,
        dept: this.user.dept,
        deptName: this.user.dept.name,
        deptCode: this.user.dept.deptCode,
        dataScope: this.user.roles && this.user.roles[0] ? this.user.roles[0].dataScope : "自身",
        roleName: this.user.roles && this.user.roles[0] ? this.user.roles[0].name : "",
      }
      this.activeUser = roleDefault
      roles.push(roleDefault)

      // 其他角色
      if (this.user.otherAccount && this.user.otherAccount.length > 0) {
        this.showSuspended = true
        this.user.otherAccount.forEach(element => {
          let role = {
            nickName: element.nickName,
            roles: element.roles,
            id: element.id,
            dept: element.dept,
            deptName: element.dept.name,
            deptCode: element.dept.deptCode,
            dataScope: element.roles && element.roles[0] ? element.roles[0].dataScope : "自身",
            roleName: element.roles && element.roles[0] ? element.roles[0].name : "",
          }
          roles.push(role)
        });
      } else {
        this.showSuspended = false
      }
      this.roleList = roles
    },
    closeChangeRole() {
      this.active = 0
      this.showDialogChangeUser = false
      this.initRoles()
    },
    handleClick(action, done) {
      let user = this.activeUser
      if (action === 'confirm') {
        if (this.active === 0) {
          this.$notify({
            type: "success",
            message: "当前登录角色与切换角色一致！",
            duration: 3000,
          });
          done();
          return
        } else {
          this.changeUser(user, done);
        }
      } else {
        done();
      }
    },
    async changeUser(user, done) {
      await changeLogin(user.id)
        .then((res) => {
          if (res) {
            store.dispatch("user/changeUser", {
              token: res.token,
              user: res.user.user,
              roles: res.user.user.roles,
            });
            setTimeout(() => {
              this.$notify({
                type: "success",
                message: "切换成功,当前角色:" + user.deptName + "[" + user.roleName + "]",
                duration: 3000,
              });
              done();
              this.initRoles();
              this.$emit("change", this.activeUser, res);
            }, 1000)
          } else {
            this.loading = false
          }
        })
        .catch((err) => {
          this.loading = false
        })
    },
    clickUnclocked(type, user) {
      this.active = type
      this.activeUser = user
    },
    onTouchStart(e) {
      this.startX = e.touches[0].clientX
      this.startY = e.touches[0].clientY
      this.offsetX = this.left
      this.offsetY = this.top
      this.isScrolling = false
    },
    onTouchMove(e) {
      const x = e.touches[0].clientX - this.startX + this.offsetX
      const y = e.touches[0].clientY - this.startY + this.offsetY
      const maxX = window.innerWidth - this.$refs.floatWindow.offsetWidth
      const maxY = window.innerHeight - this.$refs.floatWindow.offsetHeight
      this.left = x < 0 ? 0 : (x > maxX ? maxX : x)
      this.top = y < 0 ? 0 : (y > maxY ? maxY : y)
      if (Math.abs(e.touches[0].clientX - this.startX) > 5 || Math.abs(e.touches[0].clientY - this.startY) > 5) {
        this.isScrolling = true
      }
    },
    onTouchEnd(e) {
      if (!this.isScrolling) {
        if (e.changedTouches[0].clientX < window.innerWidth / 2) {
          this.left = 0
        } else {
          this.left = window.innerWidth - this.$refs.floatWindow.offsetWidth
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#SwitchRole {
  .role-container {
    text-align: end;
    margin-right: 11px;
    position: fixed;
    left: 0;
    top: 0;
    transform: translate3d(0, 0, 0);
    transition: left 0.3s ease-out;
    text-shadow: 1px 1px 1px rgba(153, 153, 153, 1);

    ::v-deep .van-button {
      background-image: url('../../assets/icon/icon1.png');
      width: 44px;
    }
  }
  ::v-deep .van-dialog__header {
    font-size: 16px !important;
    font-weight: bold !important;
  }
  .title {
    position: absolute;
    right: 6%;
    font-size: 18px;
  }

  .slot-content {
    margin-top: 15px;

    .text-box {
      border: 1px;
      height: 40px;
      font-size: 14px;
      text-align: center;
      width: 88%;
      margin-left: 6%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      margin-bottom: 15px;
    }

    .change {
      background-color: rgba(35, 171, 114, 0.1);
      font-weight: bold;
      border: 1px rgba(35, 171, 114, 0.1) solid;
      position: relative;
      overflow: hidden;
      .role-tag {
        $tag-size: 30px;
        content: '';
        position: absolute;
        background-color: rgb(35, 171, 114);
        bottom: calc(#{$tag-size} / -2);
        right: calc(#{$tag-size} / -2);
        width: $tag-size;
        height: $tag-size;
        transform: rotate(45deg);
      }
    }

    .default {
      color: #333333;
      font-weight: bold;
      border: 1px rgba(153, 153, 153, 0.3) solid;
    }
  }
}
</style>