import Config from '@/config/settings'
// 适配 Nginx 反向代理
const baseUrl = process.env.NODE_ENV === 'production' ? Config.apiProd : Config.apiDev

const api = {
  state: {
    // 图片上传
    imagesUploadApi: baseUrl + '/kernel/storage/pictures',
    // 修改头像
    updateAvatarApi: baseUrl + '/kernel/user/updateAvatar',
    // 文件上传
    fileUploadApi: baseUrl + '/kernel/storage',
    // 图片预览
    imageViewApi: baseUrl + '/kernel/storage/viewImg/',
    // baseUrl
    baseApi: baseUrl
  }
}

export default api
