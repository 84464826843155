const getters = {
  token: state => state.user.token,
  roles: state => state.user.roles,
  user: state => state.user.user,
  socketApi: state => state.api.socketApi,
  imageViewApi: state => state.api.imageViewApi,
  baseApi: state => state.api.baseApi,
  fileUploadApi: state => state.api.fileUploadApi,
  updateAvatarApi: state => state.api.updateAvatarApi,
  wsUrl: state => state.api.wsUrl
}
export default getters
